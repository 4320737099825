import {
  all, takeLatest
} from 'redux-saga/effects'

import {
  fetchBoard,
  fetchAllStates,
  saveBoardDetails,
  fetchBoardMetaDetails,
  saveBoard,
  //fetchArchivedBoard
} from '../services'
import {
  BOARD_FETCH, FETCH_STATE, SAVE_BOARD, BOARD_FETCH_SUCCEEDED,
  SAVE_BOARDLAYOUT_SUCCEEDED,
} from '../../../actionTypes'

export default function * boardWatchers () {
  yield all([
    takeLatest(BOARD_FETCH, ({
      payload: {
        id
      }
    }) => fetchBoard(id)),
    takeLatest(FETCH_STATE, ({
      payload: {
        param
      }
    }) => fetchAllStates(param)),
    /* takeLatest(FETCH_ARCHIVED_CARDS, ({
      payload: {
        id, cards
      }
    }) => fetchArchivedBoard(id, cards)), */
    takeLatest(SAVE_BOARD, ({
      payload: {
        id, 
      }
    }) => saveBoardDetails(id)),
    takeLatest(BOARD_FETCH_SUCCEEDED, ({
      payload: {
        board
      }
    }) => fetchBoardMetaDetails(board)),
    takeLatest(SAVE_BOARDLAYOUT_SUCCEEDED, ({
      payload: {
        id, data, lanes
      }
    }) => saveBoard(id, data, lanes))
  ])
}