import {
  call, put
} from 'redux-saga/effects'
import {
  toast
} from 'react-toastify'
import request from './request'
import {
  updateCardFailed,
  updateCardSucceeded,
  updateCard,
  updateCardDetailsFailed,
  updateCardDetailsSucceeded,
  updateParentCardDetailsFailed,
  updateParentCardDetailsSucceeded,
  updateCardData,
  fetchArchivedCardsSucceeded,
  fetchArchivedCardsFailed
} from '../../../actions'
import cardService from '../../../../services/api/cardService'
import boardService from '../../../../services/api/boardService'
import cardDetailsService from '../../../../services/api/cardDetailsService'

const errorConfig = {
  position: 'top-center',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
}

export function * updateCardRequest (id,
  // eslint-disable-next-line
  cardData, index, cardDetails, laneDetails, oldLaneDetails, cardOriginalOrder) {
  try {
    const taskData = {
      cards: id,
      lane_id: cardData.lane_id,
      card_order: index,
      card_originalOrder: cardOriginalOrder
    }
    const responseData = yield call(request, cardService
      .updatecardorder, taskData)

    if (responseData.data && responseData.data.data) {
      const resCardDataObj = responseData.data.data
      resCardDataObj.id = parseInt(responseData.data.data.id)
      yield put(updateCardData(resCardDataObj.id, resCardDataObj))
    }

    const action = updateCardSucceeded(responseData.data)
    yield put(action)

    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = updateCardFailed(id, error)
    yield put(action)

    return {
      success: false,
      payload: action.payload
    }
  }
}

export function * updateCardDetailsRequest (id, cardData, actualData) {
  try {
    cardData.laneid = parseInt(cardData.lane_id)
    cardData.boardid = parseInt(cardData.board_id)
    cardData.isLoading = false
    const {
      data
    } = yield call(request, boardService.updateCard, cardData)

    // yield put(updateCard(id, cardData));

    if (data && data.data) {
      const resCardDataObj = data.data
      resCardDataObj.id = parseInt(data.data.id)
      yield put(updateCardData(resCardDataObj.id, resCardDataObj))
      cardData.actual_start_date = resCardDataObj.actual_start_date
      cardData.actual_finish_date = resCardDataObj.actual_finish_date
    }
    cardData.card_order = actualData.card_order
    const action = updateCardDetailsSucceeded(id, cardData)
    yield put(action)

    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = updateCardDetailsFailed(id, error)
    yield put(action)

    return {
      success: false,
      payload: action.payload
    }
  }
}

export function * updateParentCardDetailsRequest (id, cardData) {
  try {
    const input = {
      parentID: id,
      id: cardData.id
    }

    const {
      data
    } = yield call(request, cardDetailsService.updateParentCard, input)

    cardData.parent_card = parseInt(id)

    const action = updateParentCardDetailsSucceeded(cardData.id,
      JSON.parse(JSON.stringify(cardData)))
    yield put(action)

    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = updateParentCardDetailsFailed(id, error)
    yield put(action)

    return {
      success: false,
      payload: action.payload
    }
  }
}

/* export function * fetchArchivedCards (id, archivedCards) {
  try {
    const responseData =
    yield call(request, cardDetailsService.getArchivedCards, id)
    const archivedCards = responseData.data
    archivedCards.map((item, i) => {
      archivedCards[i].archived = true
    })
    const action = fetchArchivedCardsSucceeded(id, archivedCards)
    yield put(action)
    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = fetchArchivedCardsFailed(id, error)
    yield put(action)
    return {
      success: false,
      payload: action.payload
    }
  }
} */
