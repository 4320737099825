import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import cookey from "react-cookies";
import axiosInstance from "../../../middleware/api";
import cardService from "../../../services/api/cardService";
import boardService from "../../../services/api/boardService";
import { fetchBoard, fetchState } from "../../../redux/actions";

const ImportCards = ({
  handleClose,
  open,
  boardDetails,
  refreshBoard,
  onBoardFetch,
  onClickFilter,
}) => {
  const [files, setFiles] = useState(null);
  const [loader, setLoader] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const uploadFiles = (e) => {
    setFiles(e.target.files);
  };

  const handleUpload = () => {
    const fileData = new FormData();
    fileData.append("file", files[0]);
    setLoader(true);
    axiosInstance
      .post("/api/fileupload", fileData, {
        // receive two parameter endpoint url ,form data
      })
      .then((uploadResponse) => {
        const input = {
          id: boardDetails.id,
          filepath: uploadResponse.data.path,
        };
        cardService.importCards(input).then(
          (response) => {
            setSuccessMessage(response.data);
            setLoader(false);
            // refreshBoard()
            
            boardService
              .deleteFileafterdone(uploadResponse.data.path)
              .then((response1) => {
                console.log('Deleting the own file done. ')
              })
          },
          (error) => {
            if (error) {
              setSuccessMessage(error.response.data);
              setLoader(false);
            }
          }
        );
      });
  };

  const reloadPage = () => {
    // refreshBoard()
    let constFilter;
    let userN = cookey.load("username");
    userN = userN.toLowerCase();
    const filterContLS = `${userN}_${boardDetails.id}_filterContent`;
    const quickFilterData = localStorage.getItem(filterContLS);
    const filtD = JSON.parse(quickFilterData);
    if (filtD) {
      const filterData = filtD.filterContent;
      const filterUsers = [];
      if (
        filterData["Assigned User"].length > 0 &&
        filterData["Assigned User"][0].id
      ) {
        /** for (let i = 0; i < filterData['Assigned User'].length; i++) {
          filterUsers.push(filterData['Assigned User'][i].name)
        } */
        for (const i in filterData["Assigned User"]) {
          if (filterData["Assigned User"][i].name) {
            filterUsers.push(filterData["Assigned User"][i].name);
          }
        }
        filterData["Assigned User"] = filterUsers;
      }
      constFilter = filterData;
    } else {
      constFilter = {
        my_cards: false,
        "Assigned User": [],
        Blockers: [],
        "Card Type": [],
        Category: [],
        Priority: [],
        Tags: [],
        title: "",
      };
    }
    onClickFilter();
    onBoardFetch(constFilter);
    setSuccessMessage(null);
    setFiles(null);
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="alert-dialog-title">Import Cards</DialogTitle>
      <DialogContent>
        <LoadingOverlay active={loader} spinner text="loading">
          {successMessage ? (
            <div>
              {successMessage.success && (
                <div
                  style={{
                    color: "green",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {successMessage.success}
                </div>
              )}
              {successMessage.error && (
                <div
                  style={{
                    color: "red",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {successMessage.error}
                </div>
              )}
            </div>
          ) : (
            <div>
              <p>Attach an Excel file to import cards to the current board</p>
              <form encType="multipart/form-data">
                <input id="image-file" type="file" onChange={uploadFiles} />
              </form>
            </div>
          )}
        </LoadingOverlay>
      </DialogContent>
      <DialogActions>
        {!successMessage && (
          <Button onClick={handleClose} color="default_button">
            Cancel
          </Button>
        )}
        {!successMessage ? (
          <Button
            onClick={handleUpload}
            color="primary"
            disabled={!files || loader}
          >
            Import
          </Button>
        ) : (
          <Button color="primary" onClick={reloadPage}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch, { boardDetails }) =>
  bindActionCreators(
    {
      onBoardFetch: (filter) => fetchBoard(parseInt(boardDetails.id), filter),
      onClickFilter: () => fetchState(),
    },
    dispatch
  );

const ImportCardDialog = connect(null, mapDispatchToProps)(ImportCards);

export default ImportCardDialog;
