import {
  all, call, select, put
} from 'redux-saga/effects'

import {
  fetchBoardRequest,
  saveBoardRequest,
  fetchBoardLanesCardCount,
  fetchCardMetaDetails,
  fetchBoardTags,
  fetchBoardExternalTools,
  fetchAttachmentSize,
  fetchBoardUsers,
  fetchSavedFilters,
 // fetchArchivedCards,
  fetchMySubTasks
} from '../requests'
import {
  getAllBoardSelector, getAllStatesSelector, getAllLanesSelector,
  getAllCardsSelector, stateIdsForCurrentBoardSelector,
  getAllLanesByStateIdSelector, getAllLaneChildrenByLaneIdSelector,
  getCardIdsByLaneIdSelector, getBoardDetailsByBoardIdSelector,
  getAllCardDetailsByLaneIdSelector, getAllCardsWithArchivedByLaneIdSelector,
  getCardIdsWithArchivedByLaneIdSelector
} from '../../../selectors'
import {
  clearState, applyFilter, updateCards, deleteRefLane
} from '../../../actions'

export function * fetchBoard (id, filter) {
  yield call(fetchBoardRequest, id, filter)
}

/* export function * fetchArchivedBoard (id, boards) {
  yield call(fetchArchivedCards, id, boards) 
} */

export function * fetchAllStates (param) {
  const board = yield select(getAllBoardSelector)
  const states = yield select(getAllStatesSelector)
  const lanes = yield select(getAllLanesSelector)
  const cards = yield select(getAllCardsSelector)
  const stateObj = {
    board,
    states,
    lanes,
    cards
  }
  yield put(clearState(stateObj, param))
}

export function * generateParentChildLanesRelation (parentLane) {
  const updatedParentlane = parentLane
  // Child Lane Details
  const clonedChildLanes =
  yield select(getAllLaneChildrenByLaneIdSelector, parentLane.id)
  updatedParentlane.children = clonedChildLanes
  if (updatedParentlane.action_type === 'Insert') {
    updatedParentlane.cardIds =
      yield select(getCardIdsWithArchivedByLaneIdSelector, parentLane.id)
  }

  if (clonedChildLanes && clonedChildLanes.length > 0) {
    // for (let i = 0; i < clonedChildLanes.length; i++) {
    for (const i in clonedChildLanes) {
      if (clonedChildLanes[i]) {
        yield call(generateParentChildLanesRelation,
          clonedChildLanes[i])
      }
    }
  }

  return updatedParentlane
}

export function * parseLaneDetails (laneDetailsData, result) {
  const laneDetails = JSON.parse(JSON.stringify(laneDetailsData)) // Deep copying an Object
  let data
  if (laneDetails.action_type === 'No Action') {
    const childLanes = laneDetails.children
    if (childLanes && childLanes.length > 0) {
      for (const i in childLanes) {
        if (childLanes[i]) {
          yield call(parseLaneDetails, childLanes[i], result)
        }
      }
    }
  } else {
    result.push(laneDetails)
  }
  return result
}

export function * saveBoardDetails (boardId) {
  const states = yield select(stateIdsForCurrentBoardSelector, boardId)
  const boardLanes = []
  const parentLanes = []
  /* for (let stateIndex = 0; stateIndex < states.length; stateIndex++) {
    const presentStateParentlanes = yield select(getAllLanesByStateIdSelector, states[stateIndex])
    parentLanes.push(...presentStateParentlanes)
  } */
  for (const i in states) {
    if (states[i]) {
      const presentStateParentlanes =
        yield select(getAllLanesByStateIdSelector, states[i])
      parentLanes.push(...presentStateParentlanes)
    }
  }
  const result = []
  /* for (let parentLaneIndex = 0; parentLaneIndex < parentLanes.length; parentLaneIndex++) {
    const parentLaneHierarchy = yield call(generateParentChildLanesRelation, parentLanes[parentLaneIndex])
    boardLanes.push(parentLaneHierarchy)
    yield call(parseLaneDetails, parentLanes[parentLaneIndex], result)
  } */
  for (const i in parentLanes) {
    if (parentLanes[i]) {
      const parentLaneHierarchy =
        yield call(generateParentChildLanesRelation, parentLanes[i])
      boardLanes.push(parentLaneHierarchy)
      yield call(parseLaneDetails, parentLanes[i], result)
    }
  }
  if (result.length > 0) {
    yield call(saveBoardRequest, boardId, result)
  } else {
    yield call(saveBoardRequest, boardId, boardLanes)
  }
}

export function * fetchBoardMetaDetails (board) {
  if (board && board.id) {
    const availableLanes = yield select(getAllLanesSelector)
    const boardDetails =
      yield select(getBoardDetailsByBoardIdSelector, board.id)
    if (board.savedFilter) {
      const filterData = board.savedFilter.filterContent
      const filterUsers = []
      if (filterData['Assigned User'].length > 0 &&
        filterData['Assigned User']) {
        /* for (let i = 0; i < filterData['Assigned User'].length; i++) {
          filterUsers.push(filterData['Assigned User'][i].name ? filterData['Assigned User'][i].name : filterData['Assigned User'][i])
        } */
        for (const i in filterData['Assigned User']) {
          if (filterData['Assigned User'][i]) {
            filterUsers.push(filterData['Assigned User'][i].name
              ? filterData['Assigned User'][i].name
              : filterData['Assigned User'][i])
          }
        }
        filterData['Assigned User'] = filterUsers
      }
      yield put(applyFilter(board.id, filterData, 'active'))
    }
    yield all([
      // call(fetchArchivedCards, board.id),
      call(fetchBoardLanesCardCount, board.id, availableLanes),
      // call(fetchCardMetaDetails, board.id),
      call(fetchBoardTags, board.id, boardDetails),
      call(fetchBoardExternalTools, board.id, boardDetails),
      call(fetchAttachmentSize, board.id, boardDetails),
      call(fetchBoardUsers, board.id, boardDetails),
      call(fetchSavedFilters, board.id, boardDetails),
      call(fetchMySubTasks, board.id)
    ])
  }
}

export function * saveBoard (id, data, lanes) {
  const selCards = []
  const selLanes = []
  // for (let i = 0; i < data.length; i++) {
  for (const i in data) {
    if (data[i]) {
      if (parseInt(data[i].lane_id) !== parseInt(data[i].reference_lane_id)) {
        const cardDetails =
        yield select(getAllCardsWithArchivedByLaneIdSelector,
          parseInt(data[i].lane_id))
        if (cardDetails.length > 0) {
          // for (let j = 0; j < cardDetails.length; j++) {
          for (const j in cardDetails) {
            if (cardDetails[j]) {
              const laneId = {
                lane_id: parseInt(data[i].reference_lane_id)
              }
              const card = JSON.parse(JSON.stringify(cardDetails[j]))
              card.lane_id = parseInt(data[i].reference_lane_id)
              selCards.push(card)
            }
          }
        }
        selLanes.push(parseInt(data[i].lane_id))
      }
      if (parseInt(i) === data.length - 1) {
        if (selCards.length > 0) {
          yield put(updateCards(selCards))
        }
        if (selLanes.length > 0) {
          yield put(deleteRefLane(selLanes))
        }
      }
    }
  }
}
