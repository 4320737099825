import {
  Model, attr, fk
} from 'redux-orm'
import {
  BOARD_FETCH_SUCCEEDED, CARD_UPDATE, CARD_DETAILS_UPDATE_REQUESTED,
  CARD_DETAILS_UPDATE_SUCCEEDED, PARENT_CARD_DETAILS_UPDATE_SUCCEEDED,
  CLONE_CARDS_UPDATE, DELETE_CARD, UPDATE_CARDS, CREATE_CARD,
  BOARD_FETCH_REQUESTED, CLEAR_STATE, ADD_CARDS, UPDATE_CARD_DETAILS,
  FETCH_ARCHIVED_CARDS, CARD_META_FETCH_SUCCEEDED, MY_SUBTASKS_SUCCEEDED,
  DELETE_CARDS, DELETE_CARD_IDS
} from '../../actionTypes'

export default class extends Model {
  static modelName = 'Card';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    description: attr(),
    lane_id: fk({
      to: 'Lane',
      as: 'lane',
      relatedName: 'cards'
    }),
    isLoading: attr({
      getDefault: () => false
    }),
    statsLoading: attr({
      getDefault: () => false
    })
  };

  static reducer ({
    type, payload
  }, Card) {
    switch (type) {
    case BOARD_FETCH_SUCCEEDED:
      payload.cards.forEach((card) => {
        card.filtered = true
        card.isLoading = false
        card.statsLoading = false
        Card.upsert(card)
      })

      break

    case CARD_UPDATE:
      Card.withId(payload.id).update(payload.data)

      break
    case CARD_DETAILS_UPDATE_REQUESTED:
    {
      const savedData = {
        isLoading: true
      }
      Card.withId(payload.id).update(savedData)
      break
    }

    case CARD_DETAILS_UPDATE_SUCCEEDED:
      Card.withId(payload.id).update(payload.data)
      break

    case UPDATE_CARDS:
      payload.cards.forEach((card) => {
        if (Card.withId(card.id)) {
          Card.withId(card.id).update(card)
        } else {
          Card.upsert(card)
        }
      })
      break

    case PARENT_CARD_DETAILS_UPDATE_SUCCEEDED:
      Card.withId(payload.id).update(payload.data)
      break

    case CLONE_CARDS_UPDATE:
      payload.cards.forEach((card) => {
        Card.upsert(card)
      })

      break

    case DELETE_CARD:
      Card.withId(payload.id).delete()
      break

    case DELETE_CARDS:
      payload.cards.forEach((card) => {
        Card.withId(card.id).delete()
      })
      break

    case DELETE_CARD_IDS:
      payload.cards.forEach((cardId) => {
        Card.withId(cardId).delete()
      })
      break

    case CREATE_CARD:
      Card.upsert(payload.data)
      break

    case CLEAR_STATE:
      payload.data.cards.forEach((card) => {
        Card.withId(card.id).delete()
      })
      break

    case ADD_CARDS:
      payload.cards.forEach((card) => {
        Card.upsert(card)
      })

      break

    case UPDATE_CARD_DETAILS:
      Card.withId(payload.id).update(payload.data)
      break

    case FETCH_ARCHIVED_CARDS:

      payload.cards.forEach((card) => {
        Card.upsert(card)
      })

      break

    case CARD_META_FETCH_SUCCEEDED:
      payload.data.users.forEach((card) => {
        card.isLoading = false
        Card.withId(card.id).update(card)
      })
      payload.data.stats.forEach((card) => {
        card.statsLoading = false
        Card.withId(card.id).update(card)
      })
      break

    default:
    }
  }
}
