import React, { Fragment, useState, useRef, useContext } from "react";
import {
  Grid,
  FormControl,
  Typography,
  IconButton,
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { FileUploader, FileManager } from "reactjs-file-uploader";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/GetAppSharp";
import moment from "moment";
import { event } from "jquery";
import parse from "html-react-parser";
import AlertDialog from "../../components/alert/alertComponent";
import { getInitials } from "../../utils/common";
import AttachmentService from "../../services/api/attachmentService";
import boardService from "../../services/api/boardService"
import { ErrorText, UserIcon, DownloadLink, AttachmentDesc } from "./styles";
import UserContext from "../../components/context/userContext";
import RichTextEditor from "../RichTextEditor";

const CardAttachments = ({
  data,
  allAttachments,
  updateAllAttachments,
  boardData,
}) => {
  // state declarations
  const [files, setFiles] = useState([]);

  const [alertAttachment, setAlertAttachment] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [attachmentDescription, setAttachmentDescription] = useState(null);

  const [formData, setFormData] = useState({
    maxFileSize: boardData.fileSize,
  });

  const [deleteAttachmentAlert, setDeleteAttachmentAlert] = useState(false);
  const [attachmentData, setAttachmentData] = useState(null);
  const [loader, setLoader] = useState(false);

  const fileInputRef = useRef();

  // set original name for file
  const setFileOriginalName = (file, response) => {
    files.map((fileitem, i) => {
      if (fileitem.name === file.name) {
        files[i].originalname = response.filename;
        setFiles([files]);
      }
    });
  };

  // Upload Files
  const uploadFiles = (uploadedFiles) => uploadedFiles.map(uploadFile);

  const uploadFileCompleted = (event, reponse) => {
    /** for (let i = 0; i < files.length; i++) {
      updateAttachmentEntry(files[i], data.id)
    } */
    for (const i in files) {
      if (files[i]) {
        updateAttachmentEntry(files[i], data.id);
      }
    }
  };

  // Upload a single file
  const uploadFile = (file) => (
    <FileUploader
      key={file.key}
      file={file}
      url="/api/v2/card/attachments"
      formData={{
        file,
        upload_preset: "public",
        tags: "vanilla",
      }}
      readFile
      maxFileSize={boardData.fileSize}
      autoUpload={file.size < formData.maxFileSize}
      onDownloadComplete={uploadFileCompleted}
    >
      {fileProgress}
    </FileUploader>
  );

  // show file progress
  const fileProgress = ({
    uploadReady,
    downloadComplete,
    uploadComplete,
    response,
    file,
  }) => (
    <div>
      {downloadComplete && response && setFileOriginalName(file, response)}
      {uploadReady && file.size > formData.maxFileSize ? removeEntry() : null}
    </div>
  );

  const removeEntry = () => {
    setAlertAttachment(true);
    setTimeout(() => {
      setAlertAttachment(false);
    }, 20000);
    files.map((item, i) => {
      if (item.size > formData.maxFileSize) {
        files.splice(i, 1);
        setFiles([...files]);
      }
    });
  };

  const updateAttachmentEntry = (file, cardId) => {
    file.card_id = cardId.toString();
    file.attachmentName = file.name;
    // AttachmentService.addAttachmentEntry({ file }).then(response => {
    AttachmentService.getAllAttachmentsForCard(cardId).then(
      (resAttachments) => {
        updateAllAttachments(resAttachments.data);
        // fileInputRef.current.value = '';
        setFiles([]);
      }
    );
    // });
  };

  // Download Attachment
  const downloadAttachment = (attachment) => {
    AttachmentService.downloadAttachment(attachment).then((response) => {
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = attachment.attachment_name;
      document.body.appendChild(link);
      link.click();
    });
  };

  // Open Dialog Box
  const attachmentAlertOpen = (item) => {
    setDeleteAttachmentAlert(true);
    setAttachmentData(item);
  };
  const attachmentAlertClose = () => {
    setDeleteAttachmentAlert(false);
  };
  const confirmDeleteAttachment = () => {
    deleteAttachment(attachmentData);
  };

  const deleteAttachment = (item) => {
    setLoader(true);
    AttachmentService.deleteAttachment(item).then((response) => {
      if (response.data === "successfully deleted") {
        AttachmentService.deleteAttachmentEntry({
          item,
        }).then((responseEntry) => {
          if (responseEntry.data === "Deleted successfully") {
            AttachmentService.getAllAttachmentsForCard(data.id).then(
              (resAttachments) => {
                setLoader(false);
                updateAllAttachments(resAttachments.data);
                setDeleteAttachmentAlert(false);
              }
            );
          }
        });
      }
    });
  };

  const openDescriptionDialog = (event, item) => {
    setDescriptionOpen(true);
    setAttachmentData(item);
    setAttachmentDescription(item.description ? item.description : null);
    event.preventDefault();
  };

  const updateAttachmentDescription = (value) => {
    setAttachmentDescription(value);
  };

  const saveAttachmentDescription = () => {
    const item = {
      id: attachmentData.id,
      old_description: attachmentData.description,
      description: attachmentDescription,
      card_id: attachmentData.cardid,
    };
    AttachmentService.updateDescription({
      item,
    }).then((response) => {
      AttachmentService.getAllAttachmentsForCard(data.id).then(
        (attachmentResponse) => {
          updateAllAttachments(attachmentResponse.data);
          setDescriptionOpen(false);
        }
      );
    });
  };

  const parseHtml = (htmlMessage) => {
    let returndata = "null";
    if (htmlMessage) {
      returndata = parse(htmlMessage);
    }
    return returndata;
  };

  const downloadAllAttachments = () => {
    AttachmentService.downloadAllAttachments(data.id).then((response) => {
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      // eslint-disable-next-line linebreak-style
      const str = `Attached_file_for_${data.id}_${Date.now()}.zip`;
      link.download = str;
      document.body.appendChild(link);
      link.click();
      let fileName = response.headers.filename;
      boardService
        .deleteFileafterdone(fileName)
        .then((response1) => {
          console.log('Deleting the own zip file done. ')
        })
    })
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl variant="standard">
            <div
              className="cardContainerLabel"
              style={{
                marginBottom: 10,
              }}
            >
              <span>Attachments</span>
            </div>
            {boardData.userPermission &&
            boardData.userPermission.permission.includes("card_edit") ? (
              <>
                <br />
                <Typography variant="subtitle1">
                  Select file to be attached in the card
                </Typography>
                <Typography variant="subtitle1">
                  {" "}
                  <small>
                    <i>
                      File size should not exceed
                      {boardData.fileSizeDisplay} MB{" "}
                    </i>
                  </small>
                </Typography>
                <br />
                <input
                  type="file"
                  name="fileUpload"
                  id="fileUpload"
                  ref={fileInputRef}
                  onChange={(event) =>
                    setFiles(files.concat(Array.from(event.target.files)))
                  }
                />
                {alertAttachment === true ? (
                  <ErrorText>
                    Please attach file smaller than
                    {boardData.fileSizeDisplay} MB.
                  </ErrorText>
                ) : null}
                <FileManager files={files}>{uploadFiles}</FileManager>
              </>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Button
            title="Clicking this will download all attachments as ZIP file."
            startIcon={<DownloadIcon />}
            onClick={downloadAllAttachments}
            variant="text"
            style={{
              marginRight: "20%",
              float: "right",
            }}
            color="primary"
          >
            Download all files
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />

      <Grid>
        {allAttachments.length > 0 ? (
          allAttachments.map((item, i) => (
            <Grid container spacing={1} key={item.id}>
              <Grid
                item
                xs={2}
                sm={1}
                md={1}
                style={{
                  marginLeft: 10,
                }}
                className="userIcon"
              >
                {item.username && item.username.profile_picture ? (
                  <img
                    src={item.username.profile_picture}
                    height={30}
                    width={30}
                    alt=""
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <UserIcon>
                    <div>
                      {getInitials(
                        item.username && item.username.lname
                          ? `${item.username.lname} ${
                              item.username.fname.split(" ")[0]
                            }`
                          : item.author.toString()
                      )}
                    </div>
                  </UserIcon>
                )}
              </Grid>
              <Grid item xs={8} sm={9} md={9}>
                <b
                  style={{
                    fontSize: 14,
                  }}
                >
                  <DownloadLink onClick={() => downloadAttachment(item)}>
                    {item.attachment_name}
                  </DownloadLink>
                </b>{" "}
                attached by{" "}
                <b>
                  {item.username && item.username.dname
                    ? item.username.dname
                    : item.author}
                </b>{" "}
                on {moment(item.created).format("DD-MMM-YYYY hh:mm A")}
                {/* {(boardData.userPermission && boardData.userPermission.permission.includes('card_edit'))?( */}
                <Typography>
                  {!item.description ? (
                    boardData.userPermission &&
                    boardData.userPermission.permission.includes(
                      "card_edit"
                    ) ? (
                      <Link
                        href="#"
                        onClick={(event) => openDescriptionDialog(event, item)}
                      >
                        Add Description
                      </Link>
                    ) : null
                  ) : (
                    <AttachmentDesc>
                      <span className="heading">
                        <b>Description:</b>
                        {boardData.userPermission &&
                        boardData.userPermission.permission.includes(
                          "card_edit"
                        ) ? (
                          <span className="editIcon">
                            <EditIcon
                              color="primary"
                              onClick={(event) =>
                                openDescriptionDialog(event, item)
                              }
                            />
                          </span>
                        ) : null}
                      </span>
                      <div className="ql-editor">
                        {" "}
                        {parseHtml(item.description)}{" "}
                      </div>
                    </AttachmentDesc>
                  )}
                </Typography>
                {/* ):null} */}
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                {boardData.userPermission &&
                boardData.userPermission.permission.includes("card_edit") ? (
                  <IconButton
                    aria-label="delete"
                    style={{
                      padding: 0,
                      marginLeft: 10,
                    }}
                    onClick={() => attachmentAlertOpen(item)}
                    size="large"
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                ) : null}
              </Grid>
            </Grid>
          ))
        ) : (
          <span>No attachment found</span>
        )}
      </Grid>
      {/** Delete Confirmation popup - Start */}
      <AlertDialog
        handleClose={attachmentAlertClose}
        alertopen={deleteAttachmentAlert}
        message="Are you sure want to delete the attachment ?"
        key="alertdeleteattachment"
        title="Alert"
        confirmbutton
        confirmFunc={() => confirmDeleteAttachment()}
        loader={loader}
      />
      {/** Delete Confirmation popup - End */}
      {/** */}
      <Dialog aria-labelledby="simple-dialog-title" open={descriptionOpen}>
        <DialogTitle id="alert-dialog-title" color="black">
          Add Description
        </DialogTitle>
        <DialogContent>
          <div>
            <RichTextEditor
              editorValue={attachmentDescription}
              handleEditorChange={updateAttachmentDescription}
              showTablesModule="false"
              showTextModule="true"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={saveAttachmentDescription}
            color="primary"
            variant="outlined"
            id="save_changes"
          >
            Save Changes
          </Button>
          <Button
            onClick={() => setDescriptionOpen(false)}
            variant="outlined"
            id="cancel"
            color="default_button"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/** */}
    </>
  );
};

export default CardAttachments;
